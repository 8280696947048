import cx from 'classnames';
import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import BannerImageMD from './assets/first-screen-banner-image-MD.webp';
import BannerImagePlaceholderMD from './assets/first-screen-banner-image-MD.webp?placeholder';
import BannerImageSM from './assets/first-screen-banner-image-SM.webp';
import BannerImagePlaceholderSM from './assets/first-screen-banner-image-SM.webp?placeholder';
import BannerImage from './assets/first-screen-banner-image.webp';
import BannerImagePlaceholder from './assets/first-screen-banner-image.webp?placeholder';

import styles from './part-first-screen-banner.module.scss';

export default function PartFirstScreenBanner() {
  return (
    <div className={styles.banner}>
      <PartLazyImage
        src={BannerImage}
        placeholder={BannerImagePlaceholder}
        className={cx(styles.bannerImage, styles.bannerImage_desktop)}
        alt="banner image"
      />
      <PartLazyImage
        src={BannerImageMD}
        placeholder={BannerImagePlaceholderMD}
        className={cx(styles.bannerImage, styles.bannerImage_mobileMD)}
        alt="banner image"
      />
      <PartLazyImage
        src={BannerImageSM}
        placeholder={BannerImagePlaceholderSM}
        className={cx(styles.bannerImage, styles.bannerImage_mobileSM)}
        alt="banner image"
      />
      <div className={styles.bannerContent}>
        <h1>
          Modern,
          {' '}
          <br className="visibleOnPhone" />
          <i>Energy Star certified</i>
          {' '}
          <br />
          windows and doors
        </h1>
        <ul>
          <li>Lifetime Warranty</li>
          <li>Energy Efficient</li>
          <li>Helps Increase Home Value</li>
          <li>Custom Designed</li>
          <li>Help Save On Energy Bills</li>
        </ul>
      </div>
    </div>
  );
}
