import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import footerBG from './assets/footer-bg.webp';

import styles from './part-footer.module.scss';

export default function PartFooter() {
  return (
    <div className={styles.partFooter}>
      <PartLazyImage
        src={footerBG}
        className={styles.partFooter__BG}
      />
      <div className={styles.partFooter__content}>
        <div className={styles.partFooter__copyright}>
          © 2021 Window Mart. All rights reserved.
        </div>
      </div>
    </div>
  );
}
