import React from 'react';

import PartLazyImage from '../../part-lazy-image';

import IconCause01 from './assets/icon-cause-01.svg';
import IconCause02 from './assets/icon-cause-02.svg';
import IconCause03 from './assets/icon-cause-03.svg';
import IconCause04 from './assets/icon-cause-04.svg';
import ImageCause01 from './assets/image-cause-01.webp';
import ImageCause02 from './assets/image-cause-02.webp';
import ImageCause03 from './assets/image-cause-03.webp';
import ImageCause04 from './assets/image-cause-04.webp';

import styles from './part-why-replace.module.scss';

export default function PartWhyReplace() {
  const replacementCauses = [
    {
      imageSrc: ImageCause01,
      iconNumber: '01',
      title: 'Cloudiness, \n mold',
    },
    {
      imageSrc: ImageCause02,
      iconNumber: '02',
      title: 'High heat \n cost',
    },
    {
      imageSrc: ImageCause03,
      iconNumber: '03',
      title: 'Warping, \n rotting frames',
    },
    {
      imageSrc: ImageCause04,
      iconNumber: '04',
      title: 'Peeling \n paint',
    },
  ];

  return (
    <div className={styles.partWhyReplace}>
      <div className={styles.partWhyReplace__title}>
        When and why replace
        {' '}
        <br />
        {' '}
        windows and doors?
      </div>
      <div className={styles.partWhyReplace__subTitle}>
        If you are experiencing one or more of the following problems, it&apos;s time
        {' '}
        <br />
        <span>to replace or repair your windows</span>
        {' '}
        to reduce your energy costs
      </div>
      <div className={styles.partWhyReplace__causesList}>
        {replacementCauses.map(({
          imageSrc,
          iconNumber,
          title,
        }) => (
          <div key={title} className={styles.partWhyReplace__causesListItemContainer}>
            <div className={styles.partWhyReplace__causesListItem}>
              <div className={styles.partWhyReplace__causesListItemIcon}>
                {iconNumber === '01' && <IconCause01 />}
                {iconNumber === '02' && <IconCause02 />}
                {iconNumber === '03' && <IconCause03 />}
                {iconNumber === '04' && <IconCause04 />}
              </div>
              <PartLazyImage
                src={imageSrc}
                alt=""
                className={styles.partWhyReplace__causesListItemImage}
              />
              <div className={styles.partWhyReplace__causesListItemTitle}>
                {title}
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
}
