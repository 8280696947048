import React from 'react';
import { Button as ScrollLink } from 'react-scroll';

import Logo from './assets/logo.png?base64';

import styles from './part-header.module.scss';

export default function PartHeader() {
  const menu = [
    {
      title: 'Why replace',
      scrollToElementId: 'whyReplace',
    },
    {
      title: 'Our Advantage',
      scrollToElementId: 'ourAdvantage',
    },
    {
      title: 'Technologies',
      scrollToElementId: 'technologies',
    },
  ];
  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        <img
          className={styles.logo}
          src={Logo}
          alt="company logo"
        />
      </div>
      <div className={styles.menuContainer}>
        {menu.map(({ title, scrollToElementId }) => (
          <ScrollLink
            key={title}
            className={styles.menuItem}
            to={scrollToElementId}
            smooth
            duration={500}
          >
            {title}
          </ScrollLink>
        ))}
      </div>
      <div className={styles.rightSpacer} />
    </div>
  );
}
