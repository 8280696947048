import cx from 'classnames';
import React, {
  useEffect, useRef, useState,
} from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import styles from './part-lazy-image.module.scss';

export default function PartLazyImage({
  placeholder, src, srcSet, className, alt,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef();

  const onLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) onLoad();
  });

  return (
    <div className={cx(styles.container, { [styles.hasPlaceholder]: placeholder, [styles.noPlaceholder]: !placeholder }, className)} data-loaded={isLoaded ? '1' : undefined}>
      <noscript>
        <img className={styles.noScriptImage} src={src} srcSet={srcSet} alt={alt} />
      </noscript>
      {placeholder && (
        <img
          src={placeholder}
          className={cx(styles.placeholder, styles.noScriptDisplayNone)}
          alt={alt}
        />
      )}
      <LazyLoadComponent
        visibleByDefault={isLoaded}
        delayTime={0}
        placeholder={<div className={styles.image} />}
      >
        <img
          ref={ref}
          src={src}
          srcSet={srcSet}
          className={cx(styles.image, styles.noScriptDisplayNone)}
          onLoad={onLoad}
          alt={alt}
        />
      </LazyLoadComponent>
    </div>
  );
}
