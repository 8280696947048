import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';


import PartBenefits from './part-benefits';
import PartEstimationForm from './part-estimation-form';
import FirstScreenBanner from './part-first-screen-banner';
import PartFooter from './part-footer';
import PartHeader from './part-header';
import PartReplacementProcess from './part-replacement-process';
import PartSuperiorTechnology from './part-superior-technology';
import PartWhyReplace from './part-why-replace';

import styles from './page-home.module.scss';

export default function PageHome() {
  const [showBlock, setShowBlock] = useState(false);

  useEffect(() => {
    const toggleFloatingButton = () => {
      const floatingButton = document.getElementById('floating-button');
      const endFormElement = document.getElementsByName('end-form')[0];

      if (floatingButton && endFormElement) {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        // Adjust the scroll position threshold as needed
        const threshold = endFormElement.offsetTop;
        if (scrollPosition > threshold) {
          setShowBlock(true);
        } else {
          setShowBlock(false);
        }
      }
    };

    window.addEventListener('scroll', toggleFloatingButton);

    return () => window.removeEventListener('scroll', toggleFloatingButton);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.headerContainer}>
        <PartHeader />
      </div>
      <div className={styles.firstScreenBannerContainer}>
        <FirstScreenBanner />
      </div>
      <div id="estimationForm" className={styles.formContainer}>
        <PartEstimationForm />
        <div name="end-form" />
      </div>
     <div id="ourAdvantage" className={styles.benefitsContainer}>
        <PartBenefits />
      </div>
     <div className={styles.replacementProcessContainer}>
        <PartReplacementProcess />
      </div>
      <div id="whyReplace" className={styles.whyReplaceContainer}>
        <PartWhyReplace />
      </div>
     <div id="technologies" className={styles.superiorTechnologyContainer}>
        <PartSuperiorTechnology />
      </div>
      <PartFooter />
      <ScrollLink
        id="floating-button"
        className={cx(styles.getEstimateButton, { [styles.getEstimateButton_hiden]: !showBlock })}
        to="estimationForm"
        smooth
        duration={500}
        offset={-30}
      >
        Get a Free Estimate!
      </ScrollLink>
    </div>
  );
}
